<template>
  <div class="maxBox">
    <!-- 顶部通用 -->
    <div class="boxContent">
      <!-- 头部导航区域 -->
       <div class="navbar ">
        <Navbar :itemIndex='activeIndex' />
      </div>
    </div>
    <!-- 导航条  首页/专题报道/-->
    <div class="boxContentBar">
      <div class="navigationBar">
        <div>首页</div>
        /
        <div>专题报道</div>
        /
        <div class="hoverActive">
          {{ topicArray[0] && topicArray[0].title }}
        </div>
      </div>
    </div>
    <!-- 头部轮播区域  -->
    <div class="boxContentNews">
      <!-- 新闻区域 -->
      <div class="newsContnet" v-if="topicArray[0]">
        <!-- 滚动轮播区域 -->
        <div class="newsCarousel">
         <div class="swiper-container">
           <div class="swiper-wrapper">
             <div
                     class="swiper-slide"
                     v-for="item in topicArray[0].fileList"
                     :key="item.id"
             >
               <img :src="item.filePath" alt="" />
             </div>
           </div>
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev">
              <img
                style="width:100%;height:100%"
                src="../../assets/special/atlas_previous@2x.png"
                alt=""
              />
            </div>
            <div class="swiper-button-next">
              <img
                style="width:100%;height:100%"
                src="../../assets/special/atlas_next@2x.png"
                alt=""
              />
            </div>
          </div>
          <div class="newsDescrice">
            <div class="newsDescricePageNumber">
              <div class="page">

              </div>
            </div>
          </div>
        </div>
        <!-- 图片展示区域  -->
        <div class="showSwitch">
          <div class="showSwitchTop">
            <div class="switchItem" v-for="(item, index) in topicArray[0].fileList" :key="item.id">
              <div class="switchItemImg">
                <img :src="item.filePath" alt="" />
              </div>
              <div class="mantle" v-show="index!=activeIndex"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部通用 -->
    <div class="boxContentFooter">
      <FooterAll />
    </div>
  </div>
</template>

<script>
import Navbar from '../../components/public/NavBar'
import FooterAll from '../../components/public/footerAll'
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
import { getTopicDetail } from '@/api/index'
export default {
 data() {
    return {
      topicId: this.$route.query.topicId,
      topicArray: [],
      activeIndex: 0
    }
  },
  components: {
    Navbar,
    FooterAll,
  },
  created () {
  },
  mounted() {
    this.topicDetail()
  },
  methods: {
    initSwiper() {
      let _this = this;
      this.topicArray.map(() => {
        this.$nextTick(() => {
          new Swiper('.swiper-container', {
            // 如果需要前进后退按钮
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            pagination: {
              el: '.page' ,
              type: 'fraction',
              renderFraction: function (currentClass, totalClass) {
                return '<span class="' + currentClass + '"></span>' +
                    '/' +
                    '<span class="' + totalClass + '"></span>';
              },
            },
            on: {
              slideChange: function() {
                _this.activeIndex = this.activeIndex
              }
            }
          })
        })
      })
    },
    topicDetail() {
      getTopicDetail({
        id: this.topicId
      }).then(res => {
        if(res.data.success) {
          this.topicArray = [res.data.result]
          console.log('topicArray', this.topicArray)
          this.initSwiper()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import './currencyTopic.scss';
.platform {
  z-index: 10;
}
.maxBox {
  width: 100%;
  min-width: 1180px;
  background: #fbfbfb;
  .boxContentNews {
    padding-top: 0;
  }
  .boxContentNews {
    .newsContnet {
      .showSwitch {
        padding: 40px 0 60px 0;
        width: 100%;
        .showSwitchTop,
        .showSwitchBottom {
          width: 1200px;
          .switchItem {
            width: 280px;
            height: 156px;
            margin-bottom: 20px;
            margin-right: 20px;
            background: #d8d8d8;
            display: inline-block;
            box-shadow: 0px 8px 16px 0px rgba(0, 70, 255, 0.4);
            // margin: 0 20px 20px 0;
            position: relative;
            .switchItemImg {
              width: 100%;
              height: 100%;
              > img {
                @extend .imgFill;
              }
            }
            .mantle {
              width: 280px;
              height: 157px;
              background: rgba(0, 0, 0, 1);
              opacity: 0.34;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
        .showSwitchBottom {
          padding-top: 20px;
        }
      }
    }
  }
}
.maxBox .boxContentNews .newsCarousel .newsDescrice {
  background: none;
}
.maxBox .boxContentNews {
  padding-bottom: 60px !important;
}
</style>
<style>
  .newsDescricePageNumber .swiper-pagination-current{
    font-size: 32px;
    line-height: 45px;
  }
  .newsDescricePageNumber {
    font-size: 20px;
    line-height: 45px;
    font-weight: 400;
    color: #FFFFFF;
  }
</style>
